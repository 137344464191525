<template>
  <div class="row offers mt-2">
    <b-col lg="12">
      <div class="card">
        <h4 class="h4 title text-center pt-1 mt-1">
          Generate PO (Purchase Order) 365
        </h4>
        <div class="card-body">
          <b-alert show>
            Format Report PO 365 ( pendapatan Penerbit ) di period tertentu sesuai dengan durasi yang di pilih. ( Exclude Payment Free, Gramedia.com ).
          </b-alert>

          <div v-if="isError">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong please Check Form
              <div v-for="m in errorMessage" :key="m.param">
                <li>{{ m }}</li>
              </div>
            </b-alert>
          </div>
          <div class="col-md-12">
            <div class="form-group row">
              <span class="col-md-2">Select Range</span>
              <div class="col-md-auto">
                <v-date-picker
                  v-model="range"
                  mode="date"
                  :masks="masks"
                  :max-date="new Date()"
                  is-range
                >
                  <template v-slot="{ inputValue, inputEvents, isDragging }">
                    <div class="rows">
                      <input
                        class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                        :max-date="new Date()"
                      />
                      <span class="p-2">to</span>
                      <input
                        class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                        :max-date="new Date()"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="form-group row">
              <span class="col-md-2"> Offers </span>
              <div class="col-md-auto">
                <select v-model="Form.offerType" class="form-control-sm">
                  <option
                    v-for="offer in Offers"
                    :key="offer.value"
                    :value="offer.value"
                  >
                    {{ offer.name }}
                  </option>
                </select>
              </div>
              <span class="col-md-1"> Client </span>
              <div class="col-md-2">
                <select v-model="Form.client_id" class="form-control-sm">
                  <option
                    v-for="client in clientOption"
                    :key="client.value"
                    :value="client.value"
                  >
                    {{ client.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <span class="col-md-2">Publisher</span>
              <div class="col-md-4">
                <multiselect
                  v-model="Form.vendor_id"
                  :options="vendors"
                  placeholder="Select one"
                  label="Vendor Name"
                  :close-on-select="true"
                  :custom-label="customLabel"
                  @search-change="asyncFind"
                  :searchable="true"
                  :loading="isLoadingSearchVendor"
                  track-by="name"
                >
                </multiselect>
              </div>
              <div class="col-md-2">
                <button
                  v-if="!isLoading"
                  class="btn btn-block btn-primary"
                  @click="actionSearch"
                >
                  <i class="fa fa-search"></i>
                </button>
                <button v-else class="btn btn-block btn-primary" disabled>
                  <i class="fa fa-ban"> Loading ... </i>
                </button>
              </div>
              <b-overlay
                :show="busy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="onHidden"
              >
                <div class="col justify-content-end d-flex align-items-end">
                  <button
                    class="btn btn-md btn-primary"
                    :disabled="busy"
                    @click="actionDownload"
                  >
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </b-overlay>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-auto">
                Total Disbursement
                <b> {{ formatPrice(totalDisbursement,"IDR") }} </b>
              </div>
              <div class="col-md-auto">
                Total Rows <b> {{ formatPriceBasic(totalRows) }} </b>
              </div>
            </div>
            <b-table
              sticky-header="700px"
              :items="items"
              :fields="fields"
              :busy="isLoading"
              responsive="sm"
              hover
              striped
            >
              <template #cell(No)="row">
                {{ (currentPage - 1) * perPage + (row.index + 1) }}
              </template>
              <template #cell(offerName)="row">
                <div v-if="row.item.offerTypeID != 4" style="width: 27em;">
                  <router-link
                    :to="{
                      path: `/subledger/detail/${row.item.transactionCode}`,
                    }"
                    target="_blank"
                  >
                    {{ row.item.offerName }}
                  </router-link>
                </div>
                <div v-else style="width: 10em;">
                  {{ row.item.offerName }}
                </div>
              </template>
              <template #cell(itemID)="row">
                <div v-if="isChar(row.item.itemID)" class="text-danger">
                  {{ row.item.itemID }}
                </div>
                <div v-else>
                  {{ row.item.itemID }}
                </div>
              </template>
              <template #cell(unitPrice)="row">
                <div class="text-right">
                  {{ formatPrice(row.item.unitPrice, row.item.currency) }}
                </div>
              </template>
              <template #cell(vendorID)="row">
                <div class="text-nowrap">
                  {{ row.item.vendorID }}
                </div>
              </template>
              <template #cell(confirmDeliveryDate)="row">
                <div class="text-right text-nowrap">
                  {{ new Date(row.item.confirmDeliveryDate).toDateString() }}
                </div>
              </template>
              <template #cell(originalOrderNumber)="row">
                <div v-if="row.item.offerTypeID != 4">
                  <router-link
                    v-if="row.item.offerTypeID != 4"
                    :to="{
                      path: `/order/detail?ordernumber=${row.item.originalOrderNumber}`,
                    }"
                    target="_blank"
                  >
                    {{ row.item.originalOrderNumber }}
                  </router-link>
                </div>
                <div v-else>
                  {{ row.item.item_name }}
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
        <div>
          <blockquote class="blockquote text-center">
            <p class="mb-0 font-sm">
              This Page for preview only, please download to get full data
              format Purchase order detail for upload to microsoft dynamic 365
            </p>
            <footer class="blockquote-footer">
              Gramedia Digital <cite title="Source Title">Finance Report </cite>
            </footer>
          </blockquote>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import API from '../../plugins/http';
import constant from '../../store/constant';
const clientID = Object.entries(constant.CLIENT_ID).map((val) => ({
  name: val[0].replace('_', ' '),
  value: val[1],
}));
const Unselected = [
  {
    name: 'ALL',
    value: null,
  },
];
export default {
  name: 'generateSo',
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      fields: [
        { key: 'No', label: 'No' },
        // {
        //   key: 'lineNumber',
        //   label: 'lineNumber',
        //   thStyle: { width: "20%" },
        //   tdStyle: { width: "20%" }
        // },
        {
          key: 'offerName',
          label: 'OfferName',
          thStyle: { width: '20%' },
          tdStyle: { width: '20%' },
        },
        {
          key: 'itemID',
          label: 'ItemId',
        },
        {
          key: 'qty',
          label: 'SalesQty',
          style: 'text-align:center',
        },
        {
          key: 'unitPrice',
          label: 'Disbursement',
          style: 'text-align:center',
          variant: 'info',
        },
        {
          key: 'confirmDeliveryDate',
          label: 'Sales Date',
          thStyle: { width: '20%' },
          tdStyle: { width: '20%' },
        },
        {
          key: 'vendorName',
          label: 'Vendor',
        },
        {
          key: 'vendorID',
          label: 'Vendor AX',
        },
        {
          key: 'originalOrderNumber',
          label: 'OriginalOrderNumber',
        },
        {
          key: 'pool',
          label: 'Pool',
        },
        {
          key: 'siteID',
          label: 'siteID',
        },
        {
          key: 'fdDepartment',
          label: 'Department',
        },
        {
          key: 'fdSalesChannel',
          label: 'Sales Channel',
        },
      ],
      range: {
        start: moment()
          .startOf('month')
          .subtract(1, 'M')
          .format('YYYY-MM-DD'),
        end: moment()
          .subtract(1, 'M')
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      typing: null,
      message: null,
      Form: {
        offerType: 1,
      },
      Offers: [
        {
          name: 'Single',
          value: 1,
        },
        {
          name: 'Subscription',
          value: 2,
        },
        {
          name: 'Buffet',
          value: 4,
        },
        {
          name: 'Bundle',
          value: 3,
        },
      ],
      busy: false,
      clientOption: [...Unselected, ...clientID],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.Po.isLoading,
      isError: (state) => state.Po.isError,
      totalRows: (state) => state.Po.totalRows,
      items: (state) => state.Po.items,
      vendors: (state) => state.vendors.items,
      isLoadingSearchVendor: (state) => state.vendors.isLoading,
      errorMessage: (state) => state.Po.errorMessage,
      totalDisbursement: (state) => state.Po.totalDisbursement,
    }),
  },
  watch: {
    currentPage: function() {
      this.actionGetNextPage();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Purchase Order (365) | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  methods: {
    ...mapActions('Po', ['fetchGeneratePO365']),
    ...mapActions('vendors', ['fetchVendors', 'searchVendors']),
    actionSearch() {
      let payload = {
        fromDate: moment(this.range.start).format('YYYY-MM-DD'),
        endDate: moment(this.range.end).format('YYYY-MM-DD'),
        offerType: this.Form.offerType,
        vendor_id: this.Form.vendor_id?.id,
        client_id: this.Form.client_id,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchGeneratePO365(payload);
    },
    actionGetNextPage() {
      let payload = {
        fromDate: moment(this.range.start).format('YYYY-MM-DD'),
        endDate: moment(this.range.end).format('YYYY-MM-DD'),
        offerType: this.Form.offerType,
        vendor_id: this.Form.vendor_id?.id,
        client_id: this.Form.client_id,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchGeneratePO365(payload);
    },
    actionDownload() {
      if (
        this.Form.offerType == undefined ||
        this.Form.offerType == null ||
        this.Form.offerType == ''
      ) {
        this.$swal({
          title: 'Error',
          text: `Please select offer type`,
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        });
        return;
      } else if (
        this.range.start == undefined ||
        this.range.start == null ||
        this.range.start == ''
      ) {
        this.$swal({
          title: 'Error',
          text: `Please select start date`,
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        });
        return;
      } else if (
        this.range.end == undefined ||
        this.range.end == null ||
        this.range.end == ''
      ) {
        this.$swal({
          title: 'Error',
          text: `Please select end date`,
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        });
        return;
      }

      this.busy = true;
      API.post('purchase-order/download', {
        fromDate: moment(this.range.start).format('YYYY-MM-DD'),
        endDate: moment(this.range.end).format('YYYY-MM-DD'),
        offerType: this.Form.offerType,
        vendor_id: this.Form.vendor_id?.id,
      })
        .then(() => {
          this.busy = false;
          this.$swal({
            title: 'Success',
            text: 'Download Success Please Check Your Email',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
        })
        .catch(() => {
          this.busy = false;
          this.$swal({
            title: 'Error',
            text: `Something went wrong, please contact administrator`,
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
          });
        });
    },
    formatPrice(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else if(currency =='SGD') {
          formatter = new Intl.NumberFormat('en-SG', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return '0';
    },
    onHidden() {
      this.$refs.button.focus();
    },
    formatPriceBasic(value) {
      if (!value) return 0;
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return 'Type Here';
      }
    },
    asyncFind(query) {
      if (query.length > 3) {
        setTimeout(() => {
          let payload = {
            q: query,
            limit: 10,
          };
          this.searchVendors(payload);
        }, 800);
      }
    },
    isChar(id) {
      let pattern = /[S]/g;
      let result = id.match(pattern);

      if (result) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.wd-20 {
  width: 20%;
}
</style>
